<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent scrollable>
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>Histórico de Movimentação</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-banner single-line v-if="dialog">Produto: {{ estoque.produto.descricao }}</v-banner>
      <v-divider></v-divider>

      <v-card-text v-if="loading" style="height: 400px" class="pt-1">
        <v-row style="margin-top: 20px">
          <v-col cols="12" class="text-center">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-else style="height: 400px" class="pt-1">
        <v-data-table hide-default-footer :headers="headers" :items="detalhesMovimentacao" item-key="_id" :loading="carregando" disable-pagination>
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.nota.numero }}</td>
              <td>{{props.item.nota.serie}}</td>
              <td>{{ props.item.data | formatarData }}</td>
              <td>{{ props.item.tipo }}</td>
              <td>{{ props.item.quantidade }}</td>
              <td>{{ props.item.valor | formatarMoeda }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-banner single-line>{{`Entradas: ${quantidadeEntradas}, Saídas: ${quantidadeSaidas}, Saldo: ${quantidadeEntradas - quantidadeSaidas}`}} </v-banner>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="fechar()" text>Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState, mapGetters, mapActions } from "vuex";
import formatador from '@/util/formatador';

export default {
    
    data(){
      return {
        headers: [
          { text: "Nota", value: "nota.numero", sortable: false },
          { text: "Série", value: "nota.serie", sortable: false },
          { text: "Data", value: "data", sortable: false },
          { text: "Tipo", value: "tipo", sortable: false },
          { text: "Quant.", value: "quantidade", sortable: false },
          { text: "Valor", value: "valor", sortable: false },
        ],
        busca: '',
        estoque: null,
        dialog: false,
        loading: false
      }
    },

    computed: {

      ...mapState('estoqueDeposito', {
        detalhesMovimentacao: 'detalhesMovimentacao',
        carregando: 'carregando'
      }),

      quantidadeEntradas(){
        let movimentosEntrada = this.detalhesMovimentacao.filter(estoque => estoque.tipo === 'Entrada');
        return movimentosEntrada.reduce((total, estoque) => total + estoque.quantidade, 0);
      },

      quantidadeSaidas(){
        let movimentosSaida = this.detalhesMovimentacao.filter(estoque => estoque.tipo === 'Saída');
        return movimentosSaida.reduce((total, estoque) => total + estoque.quantidade, 0);
      }
    },

    methods: {

      async abrir(produtoEstoque) {
        
        try{

          this.loading = true;
          this.dialog = true;

          this.estoque = produtoEstoque;

          await this.$store.dispatch('estoqueDeposito/detalharMovimentacao', produtoEstoque);
        }
        catch(erro){
          this.commit('setMensagemErro', erro.message);
        }
        finally{
          this.loading = false;
        }
      },

      fechar(){
        this.dialog = false;
        this.$emit('fechar');
      }
    }
}
</script>